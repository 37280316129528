import React, { useState, useEffect } from 'react';
import { getAgenda } from '../../../../crud/dedicatedEvent-crud';
import moment from 'moment';
import AgendaDatesTab from '../../GeneralComponents/Agenda/AgendaDatesTab';
import AgendaDatesMobileDropdown from '../../GeneralComponents/Agenda/AgendaDatesMobileDropdown';
import AgendaSessions from './AgendaProSession';
import AgendaTracksDropdown from './AgendaProTracks';
import Loading from '../../../../components/Loading';

const AgendaPro = ({ eventData }) => {
  const [loading, setLoading] = useState(false);
  const [agenda, setAgenda] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [agendaDates, setAgendaDates] = useState([]);
  const [selectedTrackDate, setSelectedTrackDate] = useState(moment(eventData.start_date).format('YYYY-MM-DD'));
  const [selectedTrackId, setSelectedTrackId] = useState(null);

  // Fetch agenda tracks for a specific date
  const getAgendaFunction = async (date) => {
    setLoading(true);
    try {
      const res = await getAgenda(eventData.event_id, date);
      const tracks = res.data;
      setAgenda(tracks);
      setSelectedTrackId(tracks.length > 0 ? tracks[0].track_id : null);
      setErrorMessage('');
    } catch (error) {
      setAgenda([]);
      setErrorMessage('Sessions for this date are yet to be updated');
    } finally {
      setLoading(false);
    }
  };

  // Generate all agenda dates
  const getAllAgendaDates = () => {
    const dates = [];
    const currentDate = moment(eventData.start_date);
    const finalDate = moment(eventData.end_date);

    while (currentDate.isSameOrBefore(finalDate, 'day')) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }

    setAgendaDates(dates);
  };

  useEffect(() => {
    getAgendaFunction(selectedTrackDate);
    getAllAgendaDates();
  }, [selectedTrackDate]);

  return (
    <div id="agenda" className='agenda-container'>
      <div className="d-flex justify-content-lg-center justify-content-md-center align-items-center pt-5">
        <div className="head">
          <img className="about-img d-none d-md-block d-lg-block" src="/img/about-event.svg" alt="about event" />
          <p className="section-title mb-3 mb-md-0 mb-lg-0 mb-xl-0">Agenda</p>
        </div>
      </div>
      <div className="d-block d-md-none d-lg-none d-xl-none">
        {agendaDates.length > 1 && selectedTrackDate && <AgendaDatesMobileDropdown
          agendaDates={agendaDates}
          selectedTrackDate={selectedTrackDate}
          setSelectedTrackDate={setSelectedTrackDate}
        />}
      </div>

      <div className="date-change-div d-none d-md-block d-lg-block d-xl-block mt-3 mt-md-5 mt-lg-5 mb-3">
        {agendaDates.length > 1 && (
          <AgendaDatesTab
            agendaDates={agendaDates}
            selectedTrackDate={selectedTrackDate}
            setSelectedTrackDate={setSelectedTrackDate}
            selectedDateBackground="#FDB14B"
            otherTextColor="#212529"
            background="white"
          />
        )}
      </div>

      {loading ? (
        <div className="loading d-flex justify-content-center align-items-center">
          <Loading color={`#fdb14b`} />
        </div>
      ) : agenda.length > 0 ? (
        <>
          <div className="pro-container">
            <AgendaTracksDropdown
              agendaTracks={agenda}
              onTrackSelect={(selectedTrack) => setSelectedTrackId(selectedTrack.track_id)}
            />
            <AgendaSessions
              selectedTrackData={agenda.filter((track) => track.track_id === selectedTrackId)}
              eventData={eventData}
            />
          </div>
        </>
      ) : (
        <div className="no-agenda-text">{errorMessage}</div>
      )}

      <style jsx global>{`
        .pro-container {
            padding: 0 20px;
        }
        .agenda-container {
          
          padding: 50px;   
        }
        .about-img {
          margin-bottom: -30px;
          margin-left: -18px;
        }
        .section-title {
          font-family: Prompt;
          font-size: 64px;
          font-weight: 700;
          line-height: 64px;
          text-align: left;
          color: #1c2520;
        }
        .date-change-div {
          display: flex;
          align-items: center;
          padding: 0 20px 20px;
        }
        .loading {
          margin-top: 60px;
          padding-bottom: 50px;
        }
        .no-agenda-text {
          font-family: 'figtree';
          font-size: 16px;
          line-height: 24px;
          color: #212529;
          margin-top: 20px;
          padding-left: 20px;
        }
        @media only (max-width: 768px) {
                    #agenda {
                    padding: 0 1.5rem 50px 1.5rem;
                    }
                    .section-title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    }
                    .date-change-div {
                      display: flex;
                      align-items: center;
                      padding: 0 0px 0px;
                    }
                    .pro-container {
                        padding: 0px;
                    }    
                }
      `}</style>
    </div>
  );
};

export default AgendaPro;
