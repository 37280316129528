import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { isEmpty } from 'lodash';
import eventContext from '../../../../contexts/EventContext';
import { categorizeTickets, checkTeamBasedRegStatus, getTranslation } from "../../../../helpers/common";
import CouponInput from '../../GeneralComponents/CouponInput';
import TicketInfoPro from './TicketInfoPro'

const TeamBasedComponent = dynamic(
  () => import("../../GeneralComponents/TeamBased/TeamBasedComponent"),
);

const EventPricingPro = ({ eventUrl, newQuery, getTicketDataFunction, hasCoupons, hasAccessCodes, ticketData, setTicketData, isLoading, groupDiscounts, matchedEventTranslationConfig }) => {

  const { eventData } = useContext(eventContext);
  const router = useRouter();

  const [couponCode, setCouponCode] = useState("");
  const [uncategorized, setUncategorized] = useState([]);
  const [categorized, setCategorized] = useState([]);
  const [teamRegStatus, setTeamRegStatus] = useState(0); // 0 -> No team based tickets, 1 -> Only team based tickets, 2 -> team based and individual tickets 
  const [isAccessCode, setIsAccessCode] = useState(false);

  const isReferral = !isEmpty(newQuery) && newQuery.referred_by !== "" && newQuery.referral_mode !== "" && newQuery.campaign_id !== ""



  useEffect(() => {
    const { uncategorized, categorized } = categorizeTickets(ticketData);
    const teamRegistrationStatus = checkTeamBasedRegStatus(ticketData);
    setTeamRegStatus(teamRegistrationStatus)
    setUncategorized(uncategorized);
    setCategorized(categorized);
  }, [ticketData])

  const getCouponDetails = (couponDetails = false, coupon, accessCodeTicket = false) => {
    let newTicketDetails = [];
    accessCodeTicket.length > 0 ? newTicketDetails = [...accessCodeTicket] : newTicketDetails = [...ticketData]
    const couponTickets = couponDetails.applicable_tickets;
    newTicketDetails.forEach(ticket => {
      const ticketPrice = ticket.ticket_price
      ticket.final_price = ticketPrice;
      ticket.discount_remaining = null;
      ticket.coupon_applicable = null;
      ticket.discounted_price = null;

      let finalPrice;
      //If coupon code and not access code
      if (couponDetails && !accessCodeTicket) {
        ticket.discount = null;
        if (couponTickets.includes(ticket.ticket_id)) {
          ticket.coupon_applicable = true;
          ticket.discount = couponDetails.discount;
          if (couponDetails.discount_type === 1) {
            finalPrice = ticketPrice - ticket.discount;
            ticket.discounted_price = ticket.discount;
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          } else if (couponDetails.discount_type === 2) {
            finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
            ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          }
          if (finalPrice <= 0) {
            ticket.final_price = 0;
          }
        }
      } else {
        ticket.coupon_applicable = true;
        if (teamRegStatus > 0) { //If access code is coming from a team based event
          ticket.teamBasedEvent = true;
        }
        if (ticket.discount_type === 1) {
          finalPrice = ticketPrice - ticket.discount;
          ticket.discounted_price = ticket.discount;
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        } else if (ticket.discount_type === 2) {
          finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
          ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        }
        if (finalPrice <= 0) {
          ticket.final_price = 0;
        }
      }
    })
    setCouponCode(coupon);
    setTicketData(newTicketDetails);
  };

  const removeCoupon = (isAccessCode = false) => {
    if (!isAccessCode) {
      let newTicketDetails = [...ticketData]
      newTicketDetails.forEach(ticket => {
        ticket.final_price = ticket.ticket_price;
        ticket.discount = null;
        ticket.discount_remaining = null;
        ticket.discounted_price = null;
      })
      setTicketData(newTicketDetails);
    } else if (isAccessCode) {
      getTicketDataFunction();
    }
    setCouponCode("")
  }

  return (
    <>
      <div id="tickets" className='w-100  d-flex justify-content-center align-items-center mt-0 mt-4 mt-md-4'>
        {(ticketData?.length > 0 || hasCoupons || hasAccessCodes) && <div className='content p-4 py-sm-4 w-100 w-sm-95 d-flex justify-content-center flex-column align-items-sm-center align-item-start'>
          <div className='head  mb-0 mb-sm-4'>
            <img className='about-img' src="/img/about-event.svg" alt="" />
            <h2 className="about-heading">		{matchedEventTranslationConfig?.match ? getTranslation('Tickets', matchedEventTranslationConfig?.translateTo) : `${ticketData?.length === 1 ? 'Ticket' : 'Tickets'}`}
            </h2>
          </div>
          {(hasCoupons || hasAccessCodes && !isLoading) && (
            <>
              <p className='discount-text my-2'>
                {hasCoupons && !hasAccessCodes && (matchedEventTranslationConfig?.match ? getTranslation('Apply discount code to avail discounts.', matchedEventTranslationConfig?.translateTo) : "Apply discount code to avail discounts.")}
                {!hasCoupons && hasAccessCodes && (matchedEventTranslationConfig?.match ? getTranslation('Apply access code to view hidden tickets.', matchedEventTranslationConfig?.translateTo) : "Apply access code to view hidden tickets.")}
                {hasCoupons && hasAccessCodes && (matchedEventTranslationConfig?.match ? getTranslation('Apply discount code / access code to avail discounts & view hidden tickets.', matchedEventTranslationConfig?.translateTo) : "Apply discount code / access code to avail discounts & view hidden tickets")} </p>
              <CouponInput
                getCouponDetails={getCouponDetails}
                removeCoupon={removeCoupon}
                newQuery={newQuery}
                setIsAccessCode={setIsAccessCode}
                isAccessCode={isAccessCode}
                colour={"#FDB14B"}
              />

            </>
          )}
          {teamRegStatus === 0 ? (
            (ticketData?.length === 0 ? (
              <div className='tickets container mt-5 p-3 rounded d-flex justify-content-center'> No tickets available</div>
            ) :
              (<div className='tickets container mt-0 mt-sm-5 p-0 p-sm-4 pt-0  rounded justify-content-center'>
                {
                  uncategorized.map(item => (
                    <TicketInfoPro data={item} groupDiscounts={groupDiscounts} eventUrl={eventUrl} couponCode={couponCode} newQuery={newQuery} />
                  ))
                }
                {
                  categorized.length > 0 &&
                  <div className='mx-0 mx-sm-3  my-3'>
                    {
                      categorized.map((category, categoryIndex) => (
                        <div key={category.category_id} className="accordion" id={`accordion-${category.category_id}`}>
                          {
                            category.tickets.length > 0 &&
                            <div className="category-box mt-4 accordion-item">
                              <h2 className="accordion-header" id={`heading-${category.category_id}`} style={{ marginBottom: "0px" }}>
                                <button
                                  className={`accordion-button ${category.is_expanded ? "" : "collapsed"}`}
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse-${category.category_id}`}
                                  aria-expanded={category.is_expanded}
                                  aria-controls={`collapse-${category.category_id}`}
                                >
                                  {category.category_name}
                                  {/* <span className="accordion-arrow ms-2"></span> */}
                                </button>
                              </h2>
                              <div
                                id={`collapse-${category.category_id}`}
                                className={`accordion-collapse collapse ${category.is_expanded ? "show" : ""}`}
                                aria-labelledby={`heading-${category.category_id}`}
                                data-bs-parent={`#accordion-${category.category_id}`}
                              >
                                <div className='tickets accordion-body p-0 p-sm-3'>
                                  {category.category_description &&
                                    <div className='acc-desc category-description' style={{ color: '#474747' }} dangerouslySetInnerHTML={{ __html: category.category_description }} onClick={(e) => e.stopPropagation()} />

                                  }
                                  {
                                    category.tickets.map(ticket => (
                                      <TicketInfoPro key={ticket.ticket_id} groupDiscounts={groupDiscounts} eventUrl={eventUrl} data={ticket} couponCode={couponCode} newQuery={newQuery} />
                                    ))
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
              )
            )

          ) : (
            <div className='tickets container mt-2 p-3 rounded justify-content-center'>
              <TeamBasedComponent color="#FDB14B" teamRegStatus={teamRegStatus} eventData={eventData} onJoinTeam={(team) => {
                sessionStorage.setItem("team_name", team.team_name)
                sessionStorage.setItem("team_id", team.team_id)
                sessionStorage.setItem("team_attendee_count", team.team_attendee_count);
                if (isAccessCode) {
                  sessionStorage.setItem("isEventPageTeamComp", true);
                }
                router.push(
                  `/checkout/${eventData.event_url}?teamRegType=2&team_name=${team.team_name}&team_id=${team.team_id}${couponCode ? `&selectedCode=${couponCode}` : ""}${isReferral ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}` : ""}${isAccessCode ? `&isAccessCode=1` : ""}`
                )
              }}
                onCreateTeam={(val) => {
                  sessionStorage.setItem("team_name", val.team_name)
                  sessionStorage.setItem("team_lead_name", val.team_lead_name)
                  sessionStorage.setItem("team_lead_email", val.team_lead_email)
                  if (isAccessCode) {
                    sessionStorage.setItem("isEventPageTeamComp", true);
                  }
                  router.push(
                    `/checkout/${eventData.event_url}?teamRegType=1&team_name=${val.team_name}&team_lead_name=${val.team_lead_name}&team_lead_email=${val.team_lead_email}${couponCode ? `&selectedCode=${couponCode}` : ""}${isReferral ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}` : ""}${isAccessCode ? `&isAccessCode=1` : ""}`,
                  )
                }}
                onIndividual={() => {
                  if (couponCode && isAccessCode) {
                    // if multiple hidden tickets with team_based and without team_based ticket
                    // and if select individual
                    sessionStorage.setItem("isEventPageTeamComp", true);
                    router.push(
                      `/checkout/${eventData.event_url}?teamRegType=0&selectedCode=${couponCode}${isAccessCode ? `&isAccessCode=1` : ""}`
                    )
                  } else {
                    router.push(
                      `/checkout/${eventData.event_url}?teamRegType=0`
                    )
                  }
                }}
              />
            </div>
          )}
        </div>}
      </div>
      <style jsx>{`

                .about-img{
                    margin-bottom: -51px;
                    margin-left: -28px;
                }
                .discount-text{
                    font-family: Prompt;
                    // font-size: 14px;
                    font-weight: 400;
                    // line-height: 21px;
                    // letter-spacing: 0em;
                    text-align: center;
                }
                .w-95{
                    width: 95%;
                }

                .tickets {
                  max-width: 720px;
                  background: #FFF;
                }
                .category-description{
                  padding: 0 1rem;
                }

                .discount-btn{
                    background: #FDB14B;
                    border-radius: 5px;
                    border: none;
                    margin-left: -5px;
                    padding: 5px 17px 5px 17px;
                    font-family: Prompt;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                .discount-input{
                    background: #FFFFFF;
                    border: 1px solid #FFDBA6;
                    border-radius: 5px 0px 0px 5px;
                    padding: 10px;
                    width: 60%;
                }

                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #1C2520;
                }

                .category-box {
                    border-radius: 10px !important;
                    border: 2px solid #EFE9ED;
                    background: #FFF;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                }

                .accordion-body {
                    padding: 15px;
                    background-color:#fffc !important;
                }
                .accordion-button {
                  font-size: 18px;
                  font-weight: 600;
                  border-radius: 5px !important;
                }
    
                .accordion-button:not(.collapsed) {
                  background-color: #FFF;
                  color: #212529;
                  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
                }
                .acc-desc{
                  color: #474747 !important;
                }
                
                 .accordion-button::after{
                    background-image: url("/img/accordian.svg");
                }
                .accordion-button:not(.collapsed)::after {
                   background-image: url("/img/accordian.svg");                 
                   transform: rotate(-180deg);
                }

              @media only screen and (max-width: 768px) {
                  .about-heading{
                      font-size: 42px;
                  }
                  .content{
                      margin:0px;
                      padding-top: 0.8rem !important;
                  }
                }
                @media only screen and (max-width: 576px) {
                    .about-heading{
                        font-size: 18px;
                        font-weight: 600;
                        //line-height: 23.4px;
                        text-align: start;
                        //margin-left: 21px;
                        //margin-top: -9px;

                    }
                    .content{
                        margin-top:0px !important;
                    }
                    .about-img{
                      margin-bottom: -63px;
                      margin-left: 0px;
                      width: 44.69px;
                      height: 35px;
                      display:none;
                }
                    .tickets {
                      background: #f6f6fc;
                    }
                    .discount-text{
                      font-size: 16px;
                      text-align: start;
                      line-height: 24.19px;  
                      color: #1F2439;
                    }
                     .content{
                      padding-top: 0rem !important;
                  }
                  .accordion-button{
                    font-weight:500;
                    font-size:16px;
                    padding:16px;
                    box-shadow: 0 0 0 !important;

                  }
                  .accordion-body {
                    font-weight:400 !important;
                    font-size: 14px;
                    padding: 12px 16px !important;
                  }
                  .acc-desc{
                    font-size: 16px;
                    font-weight: 400 !important;
                  }
                  accordion-button:not(.collapsed) {
                    box-shadow: 0 0 0 !important;
                }
                }

            `}</style>
    </>
  )
}

export default EventPricingPro