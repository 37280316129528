import React, { useEffect, useContext, useState } from 'react'
import eventContext from '../../../contexts/EventContext';
import { getWorkshops } from '../../../crud/dedicatedEvent-crud';
import WorkshopCard from '../../../components/WorkshopCard';
import ReadMore from '../../../components/ReadMoreText';
import { sampleWorkshopsData } from '../../../data/sampleEvent';

const EventWorkshopsPro = ({ eventUrl, samplePage }) => {
    const [workshops, setWorkshops] = useState(null);
    const { eventData } = useContext(eventContext)

    const getWorkshopsFunction = async () => {
        try {
            const res = await getWorkshops(eventUrl);
            setWorkshops(res.data);
        } catch (error) {
            console.log('Error in getting workshops');
        }
    }

    useEffect(() => {
        if (samplePage) {
            setWorkshops(sampleWorkshopsData)
        } else {
            getWorkshopsFunction();
        }
    }, [eventUrl]);

    return (
        <>
            <div className='w-100 d-flex justify-content-center align-items-center mt-0 mt-4 mt-md-4 pb-2'>
                <div className='content py-sm-4 pt-1 pb-2 px-4 w-100 d-flex justify-content-center flex-column align-items-sm-center align-items-start'>
                    <div className='head'>
                        <img className='about-img' src="/img/about-event.svg" alt="" />
                        <h2 className='about-heading'>{eventData?.workshop_section_title ? eventData?.workshop_section_title : 'Tech Workshops'}</h2>
                    </div>
                    {
                        eventData?.workshop_section_description &&
                        <div className={`d-flex w-sm-90 w-full px-sm-2 px-0 justify-content-center mt-sm-4 mt-2 text-sm-center text-start`} style={{ color: '#1F2439', }}>
                            <ReadMore
                                content={eventData.workshop_section_description}
                                maxChar={"auto"}
                                styles={{
                                    fontFamily: "Prompt",
                                    textAlign: "center",
                                    fontSize: '16px',

                                    fontWeight: '400',
                                    color: '#1F2439',
                                }}
                            />
                        </div>
                    }
                    <div className='d-flex flex-wrap px-sm-3 px-0 f-gap w-100 justify-content-center'>
                        {workshops &&
                            workshops.map(workshopValue => (
                                <div className="mt-sm-5 mt-2 workshop-card-if-only-one">

                                    <WorkshopCard workshopDetails={workshopValue} eventUrl={eventUrl} color="#FDB14B" />
                                </div>

                            ))}
                    </div>
                </div>
            </div>
            <style jsx >{`

            .view-btn{
                height: 48.240482330322266px;
                width: 132.680419921875px;
                border-radius: 5px;
                background: #f6f6fc;
                cursor: pointer;
                border: 2px solid #C4C4C4;
                border-radius: 5px;
            }

            .w-90{
                width: 90%;
            }

            .workshop-card-if-only-one{
                    max-width: 500px;
                    width:100%;
                    display: flex;
                    padding:10px 8px;
                    justify-content: center;
                }
                .f-gap{
                    gap:15px;
                }

                .site-link-text{
                    font-family: Prompt;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #1F2439 !important;
            }

                .about-img{
                       margin-bottom: -51px;
                       margin-left: -28px;
                }
               
                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #1C2520;
                }
                body{
                    color: #1F2439 !important,
                }

                @media only screen and (max-width: 768px) {
                
                .about-heading{
                    font-size: 42px;
                }
                    
                }
                @media only screen and (max-width: 576px) {
                    .workshop-card-if-only-one{
                        max-width: 350px;
                        padding: 0px 2px;
                    }
                    .about-heading{
                        font-size: 18px;
                        font-weight: 600;
                        text-align: start;
                        //margin-left: 21px;
                        //margin-top: -9px;
                    }
                    .about-img{
                        margin-bottom: -63px;
                        margin-left: 0px;
                        width: 44.69px;
                        height: 35px;
                        display:none;
                }
                    .view-btn{
                        padding: 8px 25px !important;
                        border-radius: 5px !important;
                    }
                }
                

            `}</style>
        </>
    )
}

export default EventWorkshopsPro