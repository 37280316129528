import React from 'react'
import { useState, useEffect } from 'react';
import { getSponsors } from '../../../crud/dedicatedEvent-crud';
import { sampleSponsorsData } from '../../../data/sampleEvent';
import { errorToaster } from '../../../helpers/utils';
import SideViewSponsorPro from '../GeneralComponents/SponsorProModal.js';
import TippyIcon from '../../../components/TippyIcon.js';

const EventSponsorsPro = ({ eventUrl, eventData, samplePage, disableOnClick, matchedEventTranslationConfig }) => {
    const [sponsors, setSponsors] = useState(null);
    const [selectedSponsorData, setSelectedSponsorData] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleClick = (sponsorData) => {
        if (!disableOnClick) {
            setSelectedSponsorData(sponsorData);
            setOpenModal(prev => !prev);
        }
    };

    const getAllSponsors = async () => {
        try {
            const res = await getSponsors(eventUrl);
            setSponsors(res.data);
        } catch (error) {
            errorToaster("Something went wrong while trying to get ticket, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setSponsors(sampleSponsorsData)
        } else {
            getAllSponsors();
        }
    }, [eventUrl])

    return (
        <>
            <div className=' w-100 d-flex justify-content-center align-items-center mt-3 mt-lg-5 mt-md-5 pb-0 pb-sm-2 px-4'>
                <div className='content pb-1 w-100 d-flex justify-content-center flex-column align-items-sm-center align-items-start'>
                    <div className='head'>
                        <img className='about-img' src="/img/about-event.svg" alt="" />
                        <h2 className='about-heading'>{eventData?.sponsor_section_title ? eventData?.sponsor_section_title : 'Sponsors'}</h2>
                    </div>
                    {
                        eventData?.sponsor_section_description &&
                        <div className={`d-flex w-sm-90 w-full justify-content-center text-sm-center text-start mt-sm-4 mt-2 speaker_section_description`}>
                            <div
                                dangerouslySetInnerHTML={{ __html: eventData.sponsor_section_description?.replace(/(?:\r\n|\r|\n)/g, '') || '' }}
                                styles={{
                                    fontFamily: "Prompt",
                                    textAlign: "center",
                                }}
                                style={{ color: '#1F2439' }}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    }
                    <div className='d-flex flex-wrap mt-4 justify-content-center align-items-sm-center flex-column'>

                        <div className='d-flex flex-wrap sponsors justify-content-center align-items-center'>
                            {sponsors?.uncategorized && sponsors.uncategorized.map((e) => (
                                <TippyIcon content={e.entity_name}>
                                    <img src={e.image_url} onClick={() => { (e.description || e.tags.length > 0 || e.booth_number) ? handleClick(e) : e.website_url && window.open(e.website_url, "_blank") }} alt={e.entity_name} className='mx-sm-4 mx-lg-2 mb-4 mb-md-4 mb-lg-5 sponsor-img' />
                                </TippyIcon>
                            ))}
                        </div>

                        {sponsors?.categorized && sponsors.categorized.map(item => (
                            <>
                                <div className='d-flex justify-content-center align-items-center mb-4 mt-4'>
                                    <p className='type-heading'>{item.category_name}</p>
                                </div>
                                <div className='d-flex flex-wrap sponsors justify-content-center align-items-center'>
                                    {item?.entity && item.entity.map((e) => (
                                        <TippyIcon content={e.entity_name}>
                                            <img src={e.image_url} onClick={() => { (e.description || e.tags.length > 0 || e.booth_number) ? handleClick(e) : e.website_url && window.open(e.website_url, "_blank") }} alt={e.entity_name} className='mx-sm-4 mx-lg-2  mb-4 mb-md-4 mb-lg-5 sponsor-img' />
                                        </TippyIcon>
                                    ))}
                                </div>
                            </>
                        ))}

                    </div>
                </div>
                {selectedSponsorData && (
                    <SideViewSponsorPro
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        modalData={selectedSponsorData}
                        matchedEventTranslationConfig={matchedEventTranslationConfig}
                    />
                )}
            </div>
            <style jsx>{`

                .about-img{
                    margin-bottom: -51px;
                    margin-left: -28px;
                }
                .type-heading{
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 34px;
                    line-height: 120%;
                    /* identical to box height, or 48px */
                    color: #1C2520;
                }
                .w-90{
                    width: 90%;
                }
                .sponsors img{
                    width: 263.8px;
                    height: 135.36px;
                    cursor: pointer;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                    border: 1.43px solid #DDDDDD;
                }

                .about-heading{
                    font-family: Prompt;
                    font-size: 64px;
                    font-weight: 700;
                    line-height: 64px;
                    letter-spacing: 0em;
                    text-align: center;
                }
                @media only screen and (max-width: 768px) {
                    .about-heading{
                        font-size: 42px;
                    }
                    .type-heading{
                        font-size: 28px;
                    }
                }
                @media only screen and (max-width: 576px) {
                    .about-heading{
                        font-size: 18px;
                        font-weight: 600;
                        color: #1C2520;
                        //margin-left: 21px;
                        //margin-top: -9px;
                    }
                    .about-img{
                        margin-bottom: -63px;
                        margin-left: 0px;
                        width: 44.69px;
                        height: 35px;
                        display:none;
                    }
                    .sponsors img{
                        max-width: 248.8px;
                        width:45%;
                        box-shadow: 0 0 0;
                        height: 100%;
                        border-radius: 8px;
                        margin: 5px;
                    }
                    .type-heading {
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .row-gap{
                        row-gap: 10px !important;
                    }
                }
                @media only screen and (max-width: 370px),
                       only screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 370px) {
                  .sponsors img {
                    width: 45%;
                    height: 73px;  /* Prevents image distortion */
                    -webkit-backface-visibility: hidden; /* Improves rendering in WebKit */
                    -webkit-transform: translateZ(0); /* Optional: Helps with smooth scaling */
                    margin:5px;
                  }
                }

    `}</style>
        </>
    )
}

export default EventSponsorsPro