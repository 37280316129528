import React from "react";
import { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import eventContext from "../../../contexts/EventContext";
import { getTranslation } from "../../../helpers/common";
import { addAttributesToLinks } from "../../../helpers/utils";

const EventVenuePro = ({ matchedEventTranslationConfig }) => {
  const { eventData } = useContext(eventContext);

  return (
    <>
      <div id="venue" className="w-100 d-flex justify-content-center align-items-sm-center align-items-start mt-0 mt-lg-3 mt-md-3 pb-2 px-4"
      >
        <div className="content py-0 py-sm-4 w-100 d-flex justify-content-center flex-column align-items-sm-center align-items-start ">
          <div className="head mb-1 mb-sm-5">
            <img className="about-img" src="/img/about-event.svg" alt="" />
            <h2 className="about-heading">	{matchedEventTranslationConfig?.match ? getTranslation('The Venue', matchedEventTranslationConfig?.translateTo) : "The Venue"}
            </h2>
          </div>
          {eventData.venue ? (
            <div className="venue-address py-4-5 px-sm-5 px-0 mb-4 ">
              <div className="d-flex justify-content-sm-center align-items-sm-center">
                {eventData.venue && (
                  <span className="text-center">{eventData.venue}</span>
                )}
                {eventData.city && !eventData.venue && (
                  <span className="ms-2">{eventData.city}</span>
                )}
              </div>
              {eventData.additional_venue_details_rich_text && (
                <div className="d-flex justify-content-center align-items-center mt-4 mb-2">
                  <span className="text-sm-center text-start venue-sub-address ">
                    {ReactHtmlParser(
                      addAttributesToLinks(eventData.additional_venue_details_rich_text.replaceAll(
                        'style="font-family: Hind, sans-serif;"',
                        "",
                      )),
                    )}
                  </span>
                </div>
              )}
              {eventData.latitude && eventData.longitude ? (
                <div className="d-flex justify-sm-content-center align-items-sm-center mt-3 text-center">
                  <div className="m-sm-auto google-maps" onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${eventData.venue.replace(/ /g, "+")}`, "_blank")}>
                    View on google maps{" "}
                    <img
                      src="/img/google-maps.png"
                      alt="View on google maps"
                      style={{ width: "22px", cursor: "pointer" }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {eventData.event_url === "wencarnival" ? (
            <div className="venue-address py-4-5 px-5 mb-4 ">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-sm-center text-center venue-sub-address">
                  Jawaharlal Nehru International Stadium Kochi
                </span>
                {/* <span>Kochi</span> */}
              </div>
              <div className="d-flex justify-content-center align-items-center mt-2 text-center google-maps">
                <a
                  href={`https://www.google.com/maps/place/Jawaharlal+Nehru+International+Stadium+Kochi/@9.9972875,76.2981481,17z/data=!3m1!4b1!4m6!3m5!1s0x3b080d166d3a98e3:0xc8b2f4d8eae5cacd!8m2!3d9.9972822!4d76.300723!16zL20vMGJ2andx`}
                  target="_blank"
                >
                  View on google maps{" "}
                  <img
                    src="/img/google-maps.png"
                    alt="View on google maps"
                    style={{ width: "22px", cursor: "pointer" }}
                  />
                </a>
              </div>
            </div>
          ) : (
            ""
          )}

        </div>
      </div>
      <style jsx>{`
        .google-maps{
          cursor: pointer !important;
          text-decoration: underline;
          color: #007BFF !important;
          font-size: 16px !important;
        }

        .about-img{
            margin-bottom: -51px;
            margin-left: -28px;
        }
        .type-heading{
            font-family: 'Prompt';
            font-style: normal;
            font-weight: 500;
            font-size: 34px;
            line-height: 120%;
            /* identical to box height, or 48px */
            color: #263238;
        }
        .py-4-5 {
            padding-top: 2.2rem!important;
            padding-bottom: 2.2rem!important;
        }

        .px-4-5 {
            padding-right: 2.5rem!important;
            padding-left: 2.5rem!important;
        }

        .about-heading{
            font-family: Prompt;
            font-size: 64px;
            font-weight: 700;
            line-height: 64px;
            letter-spacing: 0em;
            text-align: center;
            color: #1C2520;
        }
        
            .venue-address{
                background: #141B25;
                border-radius: 5px;
                margin-bottom: -90px;
                max-width:65%;
                z-index: 10;
                word-break: break-word;
                color: #FFFFFF !important;
            }
            .venue-address{
                max-width: 700px;
                font-family: 'Prompt';
                font-size: 24px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                color: #FFFFFF;
                
            }
            .venue-sub-address{
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                color: #FFFFFFB2;
            }

            .venue-not{
                color: #1C2520;
            }
            .map-box-container {
              width:100%;
              background-size: 105%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 4rem;
            }
            .map-box{
              height:530px;
              width:88%;
            }
            .card{
                border-radius: 25px;
            }
            .map-container{
              height:100%;
              width:100%;
              position:relative;
              border-radius:20px;
            }
            .no-map{
              background:url('img/map.png');
              position:relative;
            }
            .no-map > *{
              z-index:3;
            }
            .no-map::before{
              content:'';
              height:100%;
              width:100%;
              position:absolute;
              background:#fde6c4;
            }
            @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
              .no-map::before {
                background-color: #fde6c4;
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
              }
            }
            @media only screen and (max-width: 768px) {
            .map-box{
              width:90%;
              height:500px;
              left:0;
              right:0;
            }
            
            .about-heading{
              font-size: 42px;
            }
            .venue-address{
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              padding-left:16px !important;
              padding-right:16px !important;
              margin-left:20px !important;
              margin-right:20px !important;
            }
            .venue-sub-address{
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
                       
           }
          @media only screen and (max-width: 768px) {
              .about-heading{
                  font-size: 40px;
              }
          }
           @media only screen and (max-width: 576px) {
                .about-heading{
                  font-size: 18px;
                  font-weight: 600;
                  color: #1C2520;
                  text-align: start;
                  //margin-left: 21px;
                  //margin-top: -9px;
              }
                .about-img{
                    margin-bottom: -63px;
                    margin-left: 0px;
                    width: 44.69px;
                    height: 35px;
                    display:none;
                  }
                .venue-address{
                     margin-left: 0 !important; 
                     margin-right: 0 !important; 
                 }
              
           }
`}</style>
    </>
  );
};

export default EventVenuePro;