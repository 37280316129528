import moment from 'moment/moment'
import React, { useState, useRef, useEffect, useContext } from 'react'
import eventContext from '../../../../contexts/EventContext'
import * as workerTimers from 'worker-timers'

const EventLogoPro = () => {
    const { eventData } = useContext(eventContext)
    const [countDownDays, setDays] = useState('00')
    const [countDownHours, setHours] = useState('00')
    const [countDownMinutes, setMinutes] = useState('00')
    const [countDownSeconds, setSeconds] = useState('00')

    let interval = useRef()

    let startTimestamp = `${eventData.start_date} ${eventData.start_time}`

    const startTimer = () => {
        workerTimers.setInterval(() => {
            const tillDate = moment.utc(startTimestamp).local().valueOf();
            let now = moment().valueOf();
            let duration = tillDate - now;
            let days = Math.floor(duration / (1000 * 60 * 60 * 24))
            let hours = Math.floor((duration % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            let minutes = Math.floor((duration % (1000 * 60 * 60) / (1000 * 60)))
            let seconds = Math.floor((duration % (1000 * 60) / 1000))

            if (duration < 0) {
                clearInterval(interval.current);
            } else {
                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            }

        }, 1000)
    }

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        }
    }, [])

    return (
        <>
            <div className="col-xl-5 col-md-12 mt-xl-0 d-flex  justify-content-center justify-content-sm-start flex-column p-0 logo-container">
                <div className="info-card d-flex justify-content-center align-items-center">
                    {eventData.event_poster_url !== null ?
                        <img className="logo-img" src={eventData.event_poster_url} /> :
                        <img className="logo-img" src="/img/logo.svg" />
                    }
                </div>
                <div className="row time-container d-none d-sm-flex h-25 w-100 m-0 mt-md-3 mb-md-0 p-0 p-sm-1 p-sm-0 d-flex mx-auto">
                    <div className='d-flex justify-content-around align-items-center g-2'>
                        <div className="col-sm-3 event-time-remainder p-0   mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownDays}</span>
                            <h5>DAYS</h5>
                        </div>
                        <div className="col-sm-3 event-time-remainder p-0 mx-2  mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownHours}</span>
                            <h5>HRS</h5>
                        </div>
                        <div className="col-sm-3 event-time-remainder p-0  mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownMinutes}</span>
                            <h5>MIN</h5>
                        </div>
                        <div className="col-sm-3 event-time-remainder p-0 mx-2  mt-4 d-flex flex-column justify-content-center align-items-center">
                            <span>{countDownSeconds}</span>
                            <h5>SEC</h5>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .info-card{
                    display: flex;
                    max-height: 340px;
                    min-width: 340px;
                    border-radius: 15px;
                    align-self: center;
                    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
                    background-color: #fff;
                    z-index: 3;
                }
                .time-container{
                    max-width: 630px !important;
                }
                .logo-img{
                    max-width:  100%;
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                    border-radius: 15px;
                    object-fit: cover;
                }
                .event-time-remainder{
                    background: #FFFFFF !important;
                    height: 100px;
                    width: 100px;
                    border-radius: 20px;
                    box-shadow: 2.25px 2.25px 2.75px rgba(48, 60, 73, 0.1), -1.35px -1.35px 2.25px #FFFFFF;
                }
                .event-time-remainder span{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 42px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FDB14B;
                }
                .event-time-remainder h5{
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15.4825px;
                    line-height: 23px;
                    text-align: center;
                }
                @media only screen and (max-width: 768px) {

                    .info-card{
                        height: 100% !important;
                        width: 100% !important;
                    }
                    .event-time-remainder{
                        height: 70px;
                        width: 70px;    
                        border-radius: 20px;          
                    }
                    .event-time-remainder span{
                        font-size: 28px;
                    }
                }
                @media only screen and (max-width: 576px) {
                    .info-card{
                        height: 100% !important;
                        width: 100% !important;
                        min-width: 270px;
                        padding: 2px 2px;
                        box-shadow: 0 0 0;
                    }
                    .event-time-remainder span{
                        font-size: 25px;
                        position: relative;
                        top: 10px;
                    }
                    .event-time-remainder h5{
                        font-size: 13px;
                        position: relative;
                    }
                    .event-time-remainder{
                        height: 50px;
                        width: 50px;
                        border-radius: 10px;
                        marign-left:3px;
                    }
                    .logo-img{
                        max-height: 90%;
                        width:99%;
                    }
                    .logo-container{
                        margin-top: 17px;
                    }
                    .time-container{
                        width: 100% !important;
                    }
                    .event-time-remainder{
                        font-size: 23px;
                        width: 62px;
                        height:62px;
                    }
                    .event-time-remainder h5{
                        font-size: 12px;
                    }
                }

            `}</style>
        </>
    )
}

export default EventLogoPro