import React, { useEffect } from 'react'
import Avatar from 'react-avatar'
import { useIsMobile } from '../../../config/customHooks'
import { getTranslation } from '../../../helpers/common'
import BottomDrawerModal from './BottomDrawerModal'
import ProSideViewModal from './ProSideViewModal'

const SideViewSponsorPro = ({ openModal, setOpenModal, modalData, matchedEventTranslationConfig }) => {

    const isMobile = useIsMobile();

    const ModalContent = (
        <>
            <div className="p-1 p-md-2 p-lg-2 w-100 modal-container">
                <div className="text-end mb-3">
                    <button
                        onClick={() => setOpenModal(false)}

                        className="border-0"
                        style={{
                            background: "#FFFFFF",
                            color: "#525252"
                        }}
                    >
                        <i className="fa-solid fa-xmark" style={{ fontSize: "18px" }}></i>
                    </button>
                </div>
                <div className='d-flex flex-column flex-lg-row'>
                    <div className={`col-12 col-md-12 ${(modalData?.poc || (modalData?.tags && modalData?.tags?.length > 0)) ? "col-lg-7" : "col-lg-12"}  px-1 px-md-3 px-lg-3`}>
                        <div className="d-flex mb-4 sponsor-box justify-content-between">
                            <div className="d-flex flex-column flex-md-row flex-lg-row w-100">
                                <div className="sponsor-pro-image rounded-circle d-flex align-items-center">
                                    <img
                                        src={modalData?.image_url}
                                        alt={modalData?.name}
                                        className="image-size"
                                    />
                                </div>
                                <div className="d-flex flex-column justify-content-center sponsor-info px-2 px-md-0 px-lg-0 py-2 py-md-0 py-lg-0">
                                    {!isMobile && <p className="sponsor-name">{modalData?.entity_name}</p>}
                                    {isMobile && <div className='d-flex flex-row align-items-center'>
                                        <p className="sponsor-name mb-0">{modalData?.entity_name}</p>
                                        <div className="d-flex text-end">
                                            {modalData?.website_url &&
                                                <img onClick={() => window.open(modalData?.website_url, "_blank")} src="/img/minimallocation.svg" className='image-style' alt="link" />
                                            }
                                        </div>
                                    </div>}
                                    <p className="location">{modalData?.location}</p>
                                </div>
                            </div>
                            {!isMobile &&
                                <div className="d-flex text-end">
                                    {modalData?.website_url &&
                                        <img onClick={() => window.open(modalData?.website_url, "_blank")} src="/img/minimallocation.svg" className='image-style' alt="link" />
                                    }
                                </div>
                            }
                        </div>
                        {modalData?.description &&
                            <div className="sponsor-description mb-3">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `${modalData?.description?.replace(/(?:\r\n|\r|\n)/g, "") || ""}`
                                    }}
                                />
                            </div>
                        }
                        {modalData?.booth_number &&
                            <div className="d-flex flex-column align-items-start mb-3">
                                <p className="booth-text">Booth Number</p>
                                <div className="booth-box d-flex justify-content-center align-items-center mb-2 px-2">{modalData?.booth_number}</div>
                            </div>
                        }
                        {modalData?.poc &&
                            <div className="d-block d-lg-none flex-column mb-3 p-3 poc-box">
                                <div className="mb-2 contact-text">{matchedEventTranslationConfig?.match ? getTranslation('Contact Us', matchedEventTranslationConfig?.translateTo) : "Contact Us"} </div>
                                <div className="booth-user-box d-flex flex-row mb-3">
                                    {modalData?.poc?.image_url ?
                                        <img className='poc-image' src={modalData?.poc?.image_url} alt="POC Image" />
                                        : (modalData?.poc?.name || modalData?.poc?.designation) &&
                                        <Avatar name={modalData?.poc?.name ?? ""} className="poc-image" round={175} size={44} color="#002E6E" maxInitials={1} />
                                    }
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="poc-name">{modalData?.poc?.name}</div>
                                        <p className="poc-text">
                                            <span className='poc-designation'>{modalData?.poc?.designation}</span>, <span className='poc-organization'>{modalData?.poc?.organization}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="booth-contact-box d-flex flex-column">
                                    {modalData?.poc?.contact_number &&
                                        <div className="d-flex flex-row mb-2 align-items-center">
                                            <img className='contact-image' src="img/minimal-phone-call.svg" alt="POC Image" />
                                            <div className="contact-dail">{modalData?.poc?.dial_code} {modalData?.poc?.contact_number}</div>
                                        </div>
                                    }
                                    {modalData?.poc?.email &&
                                        <div className="d-flex flex-row mb-2 align-items-center">
                                            <img className='contact-image' src="img/minimal-mail.svg" alt="POC Image" />
                                            <div className="contact-dail">{modalData?.poc?.email}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {modalData?.representatives && modalData?.representatives?.length > 0 &&
                            <div className="mb-3">
                                <div className="mb-2 representative-text">{matchedEventTranslationConfig?.match ? getTranslation('Representatives', matchedEventTranslationConfig?.translateTo) : "Representatives"}</div>
                                <div className="d-flex flex-row flex-wrap gap-4">
                                    {modalData?.representatives
                                        ?.sort((a, b) => a.representative_order - b.representative_order) // Sort by representative_order
                                        .map((representative) => (

                                            <div
                                                key={representative?.representative_order}
                                                className="speakers-box d-flex flex-row"
                                                id={representative?.representative_order}
                                            >
                                                {representative?.image_url ?
                                                    <img
                                                        className="sponsor-image"
                                                        src={representative?.image_url}
                                                        alt="speaker"
                                                    />
                                                    : (representative?.name || representative?.designation) &&
                                                    <Avatar name={representative?.name ?? ""} className="sponsor-image" round={175} size={44} color="#002E6E" maxInitials={1} />
                                                }
                                                <div className="d-flex flex-column align-items-start">
                                                    <div className="speaker-name">{representative?.name}</div>
                                                    <p className="speaker-main">
                                                        <span className='speaker-designation'>{representative?.designation}</span>,{" "}
                                                        <span className='speaker-organization'>{representative?.organization}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>}
                    </div>
                    {(modalData?.poc || (modalData?.tags && modalData?.tags?.length > 0)) &&
                        <div className="col-12 col-md-12 col-lg-5 px-0 px-md-3 px-lg-3">
                            {modalData?.poc &&
                                <div className="d-none d-lg-flex flex-column mb-3 p-3 poc-box">
                                    <div className="mb-2 contact-text">{matchedEventTranslationConfig?.match ? getTranslation('Contact Us', matchedEventTranslationConfig?.translateTo) : "Contact Us"}</div>
                                    <div className="booth-user-box d-flex flex-row mb-3">
                                        {modalData?.poc?.image_url ?
                                            <img className='poc-image' src={modalData?.poc?.image_url} alt="POC Image" />
                                            : (modalData?.poc?.name || modalData?.poc?.designation) &&
                                            <Avatar name={modalData?.poc?.name ?? ""} className="poc-image" round="175" size="44" color="#002E6E" maxInitials={1} />
                                        }
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className="poc-name">{modalData?.poc?.name}</div>
                                            <p className="poc-text">
                                                <span className='poc-designation'>{modalData?.poc?.designation}</span>, <span className='poc-organization'>{modalData?.poc?.organization}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="booth-contact-box d-flex flex-column">
                                        {modalData?.poc?.contact_number &&
                                            <div className="d-flex flex-row mb-2 align-items-center">
                                                <img className='contact-image' src="img/minimal-phone-call.svg" alt="POC Image" />
                                                <div className="contact-dail">{modalData?.poc?.dial_code} {modalData?.poc?.contact_number}</div>
                                            </div>
                                        }
                                        {modalData?.poc?.email &&
                                            <div className="d-flex flex-row mb-2 align-items-center">
                                                <img className='contact-image' src="img/minimal-mail.svg" alt="POC Image" />
                                                <div className="contact-dail">{modalData?.poc?.email}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {modalData?.tags && modalData?.tags?.length > 0 && (
                                <div className="mb-0">
                                    <p className="my-3 tag-header">{matchedEventTranslationConfig?.match ? getTranslation('Tags', matchedEventTranslationConfig?.translateTo) : "Tags "} </p>
                                    <div className="d-flex flex-wrap my-3">
                                        {modalData?.tags.map((tag) => (
                                            <div key={tag.id} className="me-2 mb-2 tag-container">
                                                <span className="tag-name">{tag.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
            <style jsx global>{`
                    .modal-container {
                        max-height: 600px;
                    }
					.sponsor-box {
					    height: ${!isMobile ? "100px" : "auto"};
                        border: 0.5px solid rgba(0, 0, 0, 0.2);
                        border-radius: 4px;
					}
                    .image-style{
                        height: ${isMobile ? "14px" : "18px"};
                        width: ${isMobile ? "14px" : "18px"};
                        margin: ${isMobile ? "5px" : "10px"};
                    }
					.speakers-box {
					    height: 70px;
						width: auto;
                        display: inline-flex;
                        border: 0.5px solid rgba(0, 0, 0, 0.2);
                        padding: 10px;
					}
					.image-size{
                        padding: ${isMobile ? "10px" : "5px"};
					    object-fit: contain;
					    height: ${isMobile ? "auto" : "80px"};
						width: ${isMobile ? "100%" : "160px"};	
					}
					.sponsor-image {
					    height: 44px;
						width: 44px;
						border-radius: 50%;
						margin-right: 8px;
					}
					.speaker-name {
						font-family: Prompt;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        color: #333333;
						margin-bottom: 4px;
					}
					.speaker-main {
						font-family: Prompt;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
					}
					.poc-designation {
						color: #8B8D98;
					}
					.poc-organization{
						color: #62636C;
                        font-weight: 600 !important;   
					}
					.speaker-designation {
						color: #8B8D98;
					}
					.speaker-organization{
						color: #62636C;
                        font-weight: 600 !important;
					}
					.poc-box {
                        border: 0.5px solid rgba(0, 0, 0, 0.2);
                        radius: 4px;
					}
					.booth-box {
					    height: 46px;
                        min-width: 60px;
						font-family: Prompt;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: #000000;
                        border-radius: 4px;
                        border: 0.5px solid rgba(0, 0, 0, 0.2);
					}
					.booth-user-box {
					    height: 44px;
						width: auto;
                        display: inline-flex;	
					}
					.poc-image{
					    width: 44px;
						height: 44px;
						border-radius: 50%;
						margin-right: 10px;
					}
					.contact-image{
					    margin-right: 5px;
					}
					.sponsor-info{
					    font-family: Prompt;
						font-weight: 500;
						color: #000000;
					}
					.sponsor-description{
					    font-family: Prompt;
						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						color: #1E1F24;
					}
					.sponsor-name{
					    font-size: 16px;
						line-height: 20px;
						margin-bottom: 10px;
                        color: #212529;
					}	
					.location{
					    font-size: 12px;
						line-height: 18px;
                        font-weight: 500;
                        color: #1E1F24;
					}
					.poc-text{
					    font-family: Prompt;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
						color: #62636C;
					}
					.booth-text{
					    font-family: Prompt;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 22px;
						color: #1E1F24;
                        margin-bottom: 10px;
					}
					.contact-dial{
					    font-family: Prompt;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
						color: #62636C;
					}
					.poc-name{
					    font-family: Prompt;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 25px;
						color: #212529;
					}
					.poc-text{
					    font-family: Prompt;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 17px;
						color: #62636C;
					}
					.contact-text{
					    font-family: Prompt;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
						color: #1E1F24;
					}
					.representative-text{
					    font-family: Prompt;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 22px;
						color: #1E1F24;
					}
					.tag-header{
                      font-family: "Prompt";
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 24px;
                      color: #1E1F24;
                    }
                    .tag-container{
                      background-color: #fff;
                      height: 28px;
                      padding: 0px 12px 0px 12px;
                      border-radius: 4px;
                      border: 0.5px solid rgba(0, 0, 0, 0.2);
                    }
                    .tag-name{
                      font-family: "Hind";
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 20px;
                      color: #000000;
                    }
                    
				`}</style>
        </>

    )
    return (
        <>
            {!isMobile ?
                <ProSideViewModal isOpen={openModal} setIsOpen={setOpenModal} background="#FFFFFF" sponsorModal={true}>
                    {ModalContent}
                </ProSideViewModal>
                :
                <BottomDrawerModal isOpen={openModal} setIsOpen={setOpenModal} background="#FFFFFF" sponsorModal={true}>
                    {ModalContent}
                </BottomDrawerModal>}
        </>
    )
}

export default SideViewSponsorPro;